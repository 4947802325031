import React from "react"

import BriefCaseSearchIcon from "mdi-react/BriefCaseSearchIcon"
  

import PageBody from "../../components/page-body/pageBody"
import SEO from "../../components/seo"

import Banner from "../../components/banner/banner"
import app from "../../images/components/app.jpg"
import appBody1 from "../../images/components/app-body-1.jpg"

import BorderTitle from "./../../components/border-title/borderTitle"
import ComponentNavigator from "./../../components/component-navigator/componentNavigator"
import QuoteImages from "./../../components/quote-images/quoteImages"

import "./food-security-and-social-connection-app.scss"

var FoodSecurityAndSocialConnectionApp = props => (
  <>
    <SEO title="Home" keywords={["gatsby", "application", "react"]} />
    <div className="food-security-and-social-connection-app-page">
      <Banner
        src={app}
        title="Food Security and Social Connection App"
        height="32.5rem"
        heightMobile="120vw"
        backgroundPosY="center"
      />
      <PageBody className="page">
        <BorderTitle>
          Food Security and Social Connection App
        </BorderTitle>
        <div className="body">
          <img className="left" src={appBody1} alt="" /><p>Being social is no longer only in person. As our world continues to connect through the internet and other technologies, we wanted to create an application that would allow our participants to do the same.  This app gives information on food security, mental health, and social connection, while also providing a real-time chat feature for our participating seniors to talk to each other from a mobile device.</p>
        </div>
        
        <div className="navigation">
        <ComponentNavigator
            link="/components-year-two/job-training-and-placement-program"
            className="first"
            icon={BriefCaseSearchIcon}
            title="Job Training and Placement Program"
            />
          
        </div>
      </PageBody>
    </div>
  </>
  )

export default FoodSecurityAndSocialConnectionApp
